<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menu"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px">

    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :value="computedDateFormatted"
        :label="label ? label : ''"
        :clearable="disableClearable !== null && !disableClearable"
        readonly
        :hint="hint ? hint : ''"
        :persistent-hint="hint !== null"
        :prepend-icon="prependIcon ? prependIcon : ''"
        :style="`width: ${width}`"
        outlined
        v-on:click:clear="onInput(null)"
        hide-details>
      </v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      :type="type"
      :min="min"
      :max="max"
      @input="onInput">
    </v-date-picker>

  </v-menu>
</template>

<script>

  export default
  {
    name: "InputDatePicker",

    props:
    {
      value: {type: String, default: null},
      label: {type: String, default: null},
      hint: {type: String, default: null},
      prependIcon: {type: String, default: null},
      disableClearable: {type: Boolean, default: false},
      isOutline: {type: Boolean, default: false},
      width: {type: String, default: "290px"},
      type: {type: String, default: "date"},
      min: {type: String, default: null},
      max: {type: String, default: null}
    },

    data()
    {
      return {
        menu: false,
        date: (this.value) ? new Date(this.value).toISOString().substr(0, 10) : "",
      }
    },

    created()
    {
    },

    methods:
    {
      onInput(val)
      {
        this.menu = false;
        this.$emit('onInput', val);
      }
    },

    computed:
    {
      computedDateFormatted:
      {
        get()
        {
          return this.date ? this.$moment(this.date).format('L') : ""
        },
        set(val) {this.date = val ? val : null;}
      }
    },

    watch:
    {
      value(newValue, oldValue)
      {
        if (newValue !== oldValue)
        {
          this.date = newValue;
          this.onInput(newValue);
        }
      }
    }
  }
</script>

<style scoped>

</style>